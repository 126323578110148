import { loop, Cmd } from 'redux-loop';
import {assocPath, keys, values, filter, map, reject} from 'ramda';
import {pipe} from 'fp-ts/pipeable';
import {API} from 'aws-amplify';

import * as mutations from '../../graphql/mutations';

//++++++++
// TYPES +
//++++++++

//+++++++++++++++++
// REDUCER HELPERS+
//+++++++++++++++++

const submitFormHelper = async ({input, html, files}) => {
  const mutation = mutations.createProjectForm;
  const variables = {
    input
  };
  try {
    // throw {msg: 'There was an error! Please try again or call us at (859) 255-0077.', e: 'Error'};
    const sendEmail = await fetch('https://55cqd9yks8.execute-api.us-east-2.amazonaws.com/default/projectFormEmailNotification-staging', {
      method: 'post',
      mode: 'no-cors',
    //   headers: {
    //      'Access-Control-Request-Origin':'*',
    //      'Access-Control-Request-Headers':'POST',
    //   },
      body: JSON.stringify({
        customForm: {
          to: process.env.NODE_ENV === 'development' ? 'johnwcothran@gmail.com,soccadelic@gmail.com' : 'jennifer@asphaltanimals.com,johnwcothran@gmail.com,larry@asphaltanimals.com,receptionist@asphaltanimals.com',
          from: 'candrasphalt@asphaltanimals.com',
          subject: 'C&R Asphalt Employment Form',
          files,
          html,
        }
      })
    });
    // // return 'success!';
    // return await API.graphql({
    //   query: mutation,
    //   variables,
    //   authMode: 'AWS_IAM'
    // });
  } catch (e) {
    console.error(e);
    throw {msg: 'There was an error! Please try again or call us at (859) 255-0077.', e};
  }

};

//+++++++++
// ACTIONS+
//+++++++++

const UPDATE = 'actions/careers/UPDATE';
const SUBMIT_FORM = 'actions/careers/SUBMIT_FORM';
const SUBMIT_FORM_SUCCESS = 'actions/careers/SUBMIT_FORM_SUCCESS';
const SUBMIT_FORM_FAIL = 'actions/careers/SUBMIT_FORM_FAIL';

//++++++++++
// REDUCERS+
//++++++++++

export const questions = {
  personalSection: {
    questionTextTitle: 'Personal Information',
    noInput: true
  },
  firstName: {
    answer: null,
    required: true,
    type: 'firstName',
    label: 'First Name',
    inputPropType: 'text'
  },
  middleName: {
    answer: null,
    required: false,
    type: 'middleName',
    label: 'Middle Name',
    inputPropType: 'text'
  },
  lastName: {
    answer: null,
    required: true,
    type: 'lastName',
    label: 'Last Name',
    inputPropType: 'text'
  },
  startDate: {
    answer: null,
    required: true,
    type: 'startDate',
    label: 'Start Date',
    inputPropType: 'date'
  },
  email: {
    answer: null,
    required: true,
    type: 'email',
    label: 'Email',
    inputPropType: 'email'
  },
  phone: {
    answer: null,
    required: true,
    type: 'phone',
    label: 'Phone',
    inputPropType: 'tel'
  },
  resumeFile: {
    questionText: 'Resume Upload',
    answer: null,
    required: false,
    type: 'resumeFile',
    label: 'Resume Upload',
    inputPropType: 'file'
  },
  addressStreetLine1: {
    questionText: 'Address',
    answer: null,
    required: true,
    type: 'addressStreetLine1',
    label: 'Street Address',
    inputPropType: 'text'
  },
  addressStreetLine2: {
    // questionText: 'What is your last name?',
    answer: null,
    required: false,
    type: 'addressStreetLine2',
    label: 'Street Address Line 2',
    inputPropType: 'text'
  },
  addressCity: {
    // questionText: 'What is your last name?',
    answer: null,
    required: true,
    type: 'addressCity',
    label: 'City',
    inputPropType: 'text'
  },
  addressState: {
    answer: null,
    required: true,
    type: 'addressState',
    label: 'State/Province',
    inputPropType: 'text'
  },
  addressZipCode: {
    answer: null,
    required: true,
    type: 'addressZipCode',
    label: 'Postal / Zip Code',
    inputPropType: 'zip'
  },
  addressHowLong: {
    answer: null,
    required: true,
    type: 'addressHowLong',
    label: 'How long have you lived here?',
    inputPropType: 'text'
  },
  eligibleToWorkInUS: {
    questionText: 'Are you eligible to work in the United States? (Proof of eligibility will be required before you can be employed)',
    answer: null,
    required: true,
    type: 'eligibleToWorkInUS',
    options: [
      { label: 'Yes', value: 'Yes' },
      { label: 'No', value: 'No' },
    ]
  },
  validKentuckyDriversLicense: {
    questionText: 'Do you have a current valid Kentucky driver’s license?',
    answer: null,
    required: true,
    type: 'validKentuckyDriversLicense',
    options: [
      { label: 'Yes', value: 'Yes' },
      { label: 'No', value: 'No' },
    ]
  },
  atLeast18: {
    questionText: 'Are you at least 18 years old?',
    answer: null,
    required: true,
    type: 'atLeast18',
    options: [
      { label: 'Yes', value: 'Yes' },
      { label: 'No', value: 'No' },
    ]
  },
  workPermit: {
    questionText: 'If no, do you have a work permit?',
    answer: null,
    required: true,
    type: 'workPermit',
    options: [
      { label: 'Yes', value: 'Yes' },
      { label: 'No', value: 'No' },
    ]
  },
  appliedBefore: {
    questionText: 'Have you ever applied here before?',
    answer: null,
    required: true,
    type: 'appliedBefore',
    options: [
      { label: 'Yes', value: 'Yes' },
      { label: 'No', value: 'No' },
    ]
  },
  appliedBeforeDate: {
    questionText: 'If you have applied before, state the date.',
    answer: null,
    required: true,
    type: 'date',
    // label: 'Start Date',
    inputPropType: 'date'
  },
  otherCompanyLeave: {
    questionText: 'Are you presently on layoff or leave of absence from any other company?',
    answer: null,
    required: true,
    type: 'otherCompanyLeave',
    options: [
      { label: 'Yes', value: 'Yes' },
      { label: 'No', value: 'No' },
    ]
  },
  convicted: {
    questionTextHeader: 'Have you ever been convicted of any law violation or have you forfeited collateral (i.e. bond)? (An affirmative answer will not automatically disqualify you from being considered as a candidate for employment.)',
    questionTextSubHeader: 'You may omit: (1) Traffic violations in which a fine of $100.00 or less was imposed (unless the violation involved alcohol or drugs) and (2) Any offense committed before your eighteenth (18th) birthday which was finally adjudicated in a juvenile court or under a youth offender law.',
    answer: null,
    required: true,
    type: 'convicted',
    options: [
      { label: 'Yes', value: 'Yes' },
      { label: 'No', value: 'No' },
    ]
  },
  convictedDetails: {
    questionTextSubHeader: "If yes, state the date of such offense(s), charge(s), place(s), of occurrence, court(s) involved and action(s) taken:",
    answer: null,
    // required: true,
    type: 'convictedDetails',
    // label: 'Message to C & R',
    inputPropType: 'text',
    multiline: true
  },
  convictedExplanation: {
    questionTextSubHeader: "If yes, explain:",
    answer: null,
    // required: true,
    type: 'convictedExplanation',
    // label: 'Message to C & R',
    inputPropType: 'text',
    multiline: true
  },
  otherCompanyAgreements: {
    questionText: 'Do you have any agreements with another employer that might affect your employment here?',
    answer: null,
    required: true,
    type: 'otherCompanyAgreements',
    options: [
      { label: 'Yes', value: 'Yes' },
      { label: 'No', value: 'No' },
    ]
  },
  otherCompanyAgreementsExplanation: {
    questionTextSubHeader: "If yes, explain:",
    answer: null,
    // required: true,
    type: 'otherCompanyAgreementsExplanation',
    // label: 'Message to C & R',
    inputPropType: 'text',
    multiline: true
  },
  referencesSection: {
    questionTextTitle: 'References',
    questionTextHeader: 'Contact #1',
    noInput: true
  },
  // firstNameReference1: {
  //   answer: null,
  //   required: true,
  //   type: 'firstNameReference1',
  //   label: 'First Name',
  //   inputPropType: 'text'
  // },
  // lastNameReference1: {
  //   answer: null,
  //   required: true,
  //   type: 'lastNameReference1',
  //   label: 'Last Name',
  //   inputPropType: 'text'
  // },
  nameReference1: {
     answer: null,
     required: true,
     type: 'nameReference1',
     label: 'Contact 1 Name',
     inputPropType: 'text'
  },
  occupationReference1: {
    answer: null,
    required: true,
    type: 'occupationReference1',
    label: 'Occupation',
    inputPropType: 'text'
  },
  relationshipReference1: {
    answer: null,
    required: false,
    type: 'relationshipReference1',
    label: 'Relationship to you',
    inputPropType: 'text'
  },
  emailReference1: {
    answer: null,
    required: true,
    type: 'emailReference1',
    label: 'Email (Contact 1)',
    inputPropType: 'email'
  },
  phoneReference1: {
    answer: null,
    required: true,
    type: 'phoneReference1',
    label: 'Phone Number (Contact 1)',
    inputPropType: 'tel'
  },
  addressStreetLine1Reference1: {
    questionText: 'Address (Contact 1)',
    answer: null,
    required: true,
    type: 'addressStreetLine1Reference1',
    label: 'Street Address (Contact 1)',
    inputPropType: 'text',
    subFields: [
      {key: 'addressStreetLine1Reference1', label: 'Street Address', keep: true},
      {key: 'addressStreetLine2Reference1'},
      {key: 'addressCityReference1'},
      {key: 'addressStateReference1'},
      {key: 'addressZipCodeReference1'},
    ]
  },
  addressStreetLine2Reference1: {
    // questionText: 'What is your last name?',
    answer: null,
    required: false,
    type: 'addressStreetLine2Reference1',
    label: 'Street Address Line 2',
    inputPropType: 'text'
  },
  addressCityReference1: {
    // questionText: 'What is your last name?',
    answer: null,
    required: true,
    type: 'addressCityReference1',
    label: 'City',
    inputPropType: 'text'
  },
  addressStateReference1: {
    answer: null,
    required: true,
    type: 'addressStateReference1',
    label: 'State/Province',
    inputPropType: 'text'
  },
  addressZipCodeReference1: {
    answer: null,
    required: true,
    type: 'addressZipCodeReference1',
    label: 'Postal / Zip Code',
    inputPropType: 'zip'
  },
  referencesContact2Section: {
    questionTextHeader: 'Contact #2',
    noInput: true
  },
  nameReference2: {
     answer: null,
     required: false,
     type: 'nameReference2',
     label: 'Contact 2 Name',
     inputPropType: 'text'
  },
  // firstNameReference2: {
  //   answer: null,
  //   required: false,
  //   type: 'firstNameReference2',
  //   label: 'First Name',
  //   inputPropType: 'text'
  // },
  // lastNameReference2: {
  //   answer: null,
  //   required: false,
  //   type: 'lastNameReference2',
  //   label: 'Last Name',
  //   inputPropType: 'text'
  // },
  occupationReference2: {
    answer: null,
    required: false,
    type: 'occupationReference2',
    label: 'Occupation',
    inputPropType: 'text'
  },
  relationshipReference2: {
    answer: null,
    required: false,
    type: 'relationshipReference2',
    label: 'Relationship to you',
    inputPropType: 'text'
  },
  emailReference2: {
    answer: null,
    required: false,
    type: 'emailReference2',
    label: 'Email',
    inputPropType: 'email'
  },
  phoneReference2: {
    answer: null,
    required: false,
    type: 'phoneReference2',
    label: 'Phone Number (Contact 2)',
    inputPropType: 'tel'
  },
  addressStreetLine1Reference2: {
    questionText: 'Address (Contact 2)',
    answer: null,
    required: false,
    type: 'addressStreetLine1Reference2',
    label: 'Street Address (Contact 2)',
    inputPropType: 'text',
    subFields: [
      {key: 'addressStreetLine1Reference2', label: 'Street Address', keep: true},
      {key: 'addressStreetLine2Reference2'},
      {key: 'addressCityReference2'},
      {key: 'addressStateReference2'},
      {key: 'addressZipCodeReference2'},
    ]
  },
  addressStreetLine2Reference2: {
    // questionText: 'What is your last name?',
    answer: null,
    required: false,
    type: 'addressStreetLine2Reference2',
    label: 'Street Address Line 2',
    inputPropType: 'text'
  },
  addressCityReference2: {
    // questionText: 'What is your last name?',
    answer: null,
    required: false,
    type: 'addressCityReference2',
    label: 'City',
    inputPropType: 'text'
  },
  addressStateReference2: {
    answer: null,
    required: false,
    type: 'addressStateReference2',
    label: 'State/Province',
    inputPropType: 'text'
  },
  addressZipCodeReference2: {
    answer: null,
    required: false,
    type: 'addressZipCodeReference2',
    label: 'Postal / Zip Code',
    inputPropType: 'zip'
  },
  referencesContact3Section: {
    questionTextHeader: 'Contact #3',
    noInput: true
  },
  // firstNameReference3: {
  //   answer: null,
  //   required: false,
  //   type: 'firstNameReference3',
  //   label: 'First Name',
  //   inputPropType: 'text'
  // },
  // lastNameReference3: {
  //   answer: null,
  //   required: false,
  //   type: 'lastNameReference3',
  //   label: 'Last Name',
  //   inputPropType: 'text'
  // },
  nameReference3: {
     answer: null,
     required: false,
     type: 'nameReference3',
     label: 'Contact 3 Name',
     inputPropType: 'text'
  },
  occupationReference3: {
    answer: null,
    required: false,
    type: 'occupationReference1',
    label: 'Occupation',
    inputPropType: 'text'
  },
  relationshipReference3: {
    answer: null,
    required: false,
    type: 'relationshipReference3',
    label: 'Relationship to you',
    inputPropType: 'text'
  },
  emailReference3: {
    answer: null,
    required: false,
    type: 'emailReference3',
    label: 'Email (Contact 3)',
    inputPropType: 'email'
  },
  phoneReference3: {
    answer: null,
    required: false,
    type: 'phoneReference3',
    label: 'Phone Number (Contact 3)',
    inputPropType: 'tel'
  },
  addressStreetLine1Reference3: {
    questionText: 'Address (Contact 1)',
    answer: null,
    required: false,
    type: 'addressStreetLine1Reference3',
    label: 'Street Address (Contact 3)',
    inputPropType: 'text',
    subFields: [
      {key: 'addressStreetLine1Reference1', label: 'Street Address', keep: true},
      {key: 'addressStreetLine2Reference1'},
      {key: 'addressCityReference1'},
      {key: 'addressStateReference1'},
      {key: 'addressZipCodeReference1'},
    ]
  },
  addressStreetLine2Reference3: {
    // questionText: 'What is your last name?',
    answer: null,
    required: false,
    type: 'addressStreetLine2Reference3',
    label: 'Street Address Line 2',
    inputPropType: 'text'
  },
  addressCityReference3: {
    // questionText: 'What is your last name?',
    answer: null,
    required: false,
    type: 'addressCityReference3',
    label: 'City',
    inputPropType: 'text'
  },
  addressStateReference3: {
    answer: null,
    required: false,
    type: 'addressStateReference3',
    label: 'State/Province',
    inputPropType: 'text'
  },
  addressZipCodeReference3: {
    answer: null,
    required: false,
    type: 'addressZipCodeReference3',
    label: 'Postal / Zip Code',
    inputPropType: 'zip'
  },
  employmentType: {
    questionTextHeader: 'Work Preferences',
    questionTextSubHeader: 'Answer only the questions for which you are applying.',
    questionText: 'What type of employment do you want?',
    answer: null,
    required: true,
    type: 'employmentType',
    options: [
      { label: 'Full-Time', value: 'Full-Time' },
      { label: 'Seasonal', value: 'Seasonal' },
    ]
  },
  minimumSalary: {
    answer: null,
    required: false,
    type: 'minimumSalary',
    label: 'What is your minimum salary requirement?',
    inputPropType: 'text'
  },
  jobTypePreference: {
    questionText: 'What type of job do you prefer?',
    answer: null,
    required: true,
    type: 'jobTypePreference',
    options: [
      { label: 'CDL Driver', value: 'CDL Driver' },
      { label: 'Mechanic', value: 'Mechanic' },
      { label: 'Labor: Paving', value: 'Labor: Paving' },
      { label: 'Labor: Sealing', value: 'Labor: Sealing' },
      { label: 'Labor: Heavy Equipment Operator', value: 'Labor: Heavy Equipment Operator' },
    ]
  },
  willingToRelocate: {
    questionText: 'Are you willing to relocate?',
    answer: null,
    required: true,
    type: 'willingToRelocate',
    options: [
      { label: 'Yes', value: 'Yes' },
      { label: 'No', value: 'No' },
    ]
  },
  percentOfTime: {
    answer: null,
    required: false,
    type: 'percentOfTime',
    label: 'What percent of time?',
    inputPropType: 'text'
  },
  pastWorkExperienceDescription: {
    questionTextSubHeader: "What past work experience do you feel is most applicable to this position?",
    answer: null,
    // required: true,
    type: 'pastWorkExperienceDescription',
    // label: 'Message to C & R',
    inputPropType: 'text',
    multiline: true
  },
  signature: {
    questionTextTitle: "As an applicant for employment, I understand the following:",
    questionTextBullets: [
      'This application will remain on active file for sixty (60) days.  If I am hired within this period, this form will be transferred to my individual personnel file.',
      'If I am not hired within sixty (60) days, this application is no longer active and I will need to reapply for employment if I wish to be considered for a position after that time.',
      'Any misrepresentation or falsification of information or significant omissions will be cause for rejection of my application or for subsequent discipline up to and including my dismissal from employment if discovered at a later date.',
      'After an offer of employment has been extended, I may be required to submit to a physical examination.  This may include a drug screen, in order to determine my physical ability to perform my job duties with or without accommodation.',
      'My employment may be contingent upon the result of this examination and drug screen.  If the results of the physical examination indicate that I cannot perform the job for which an offer has been extended and/or if the drug screen results are positive for substance abuse, this will be grounds for disqualifying me or terminating my offer of employment.',
      'If my application for employment is accepted, the effective date of employment shall be the time I actually begin to work.  If I am employed, I agree to comply with and be bound to the safety and health rules and regulations, and the standards of conduct of my employer.',
      'My employment is not guaranteed for any term, and my employment may be terminated by my employer or myself at any time and for any reason.  No management official is authorized to make any oral assurance or promise of continued employment.',
      'All information (including information on any accompanying resume) is subject to verification.',
    ],
    questionTextSubHeader: 'I authorize you to contact and I authorize any present or former employer, education institution, law enforcement agency, financial institution, or other persons having personal knowledge about me to furnish any and all information in their possession regarding me, in connection with any decision concerning my employment.  Further, I hereby release from liability and hold harmless all persons and corporations supplying this information.  A photocopy of this authorization is as valid as the original.',
    answer: null,
    required: true,
    type: 'signature',
    label: 'Signature (Type full name)',
    inputPropType: 'text',
  },
  signatureDate: {
    answer: null,
    required: true,
    type: 'signatureDate',
    label: "Today's Date",
    inputPropType: 'date'
  },

  // EMPLOYMENT SECTION
  EmploymentSection: {
    questionTextTitle: 'Employment Data',
    noInput: true
  },
  // EMPLOYER 1
  nameEmployer1: {
    questionTextHeader: 'Employer 1',
     answer: null,
     required: false,
     type: 'nameEmployer1',
     label: 'Employer Name',
     inputPropType: 'text'
  },
  phoneEmployer1: {
    answer: null,
    required: false,
    type: 'phoneEmployer1',
    label: 'Phone Number (Employer 1)',
    inputPropType: 'tel'
  },
  addressStreetLine1Employer1: {
    questionText: 'Address (Employer 1)',
    answer: null,
    required: false,
    type: 'addressStreetLine1Employer1',
    label: 'Street Address (Employer 1)',
    inputPropType: 'text',
    subFields: [
      {key: 'addressStreetLine1Employer1', label: 'Street Address', keep: true},
      {key: 'addressStreetLine2Employer1'},
      {key: 'addressCityEmployer1'},
      {key: 'addressStateEmployer1'},
      {key: 'addressZipCodeEmployer1'},
    ]
  },
  addressStreetLine2Employer1: {
    // questionText: 'What is your last name?',
    answer: null,
    required: false,
    type: 'addressStreetLine2Employer1',
    label: 'Street Address Line 2',
    inputPropType: 'text'
  },
  addressCityEmployer1: {
    // questionText: 'What is your last name?',
    answer: null,
    required: false,
    type: 'addressCityEmployer1',
    label: 'City',
    inputPropType: 'text'
  },
  addressStateEmployer1: {
    answer: null,
    required: false,
    type: 'addressStateEmployer1',
    label: 'State/Province',
    inputPropType: 'text'
  },
  addressZipCodeEmployer1: {
    answer: null,
    required: false,
    type: 'addressZipCodeEmployer1',
    label: 'Postal / Zip Code',
    inputPropType: 'zip'
  },
  startDateEmployer1: {
    answer: null,
    required: false,
    type: 'startDateEmployer1',
    label: 'Start Date',
    inputPropType: 'date'
  },
  endDateEmployer1: {
    answer: null,
    required: false,
    type: 'endDateEmployer1',
    label: 'Start Date',
    inputPropType: 'date'
  },
  employmentTypeEmployer1: {
    questionText: 'Type of employment',
    answer: null,
    required: false,
    type: 'employmentTypeEmployer1',
    options: [
      { label: 'Full-Time', value: 'Full-Time' },
      { label: 'Part-Time', value: 'Part-Time' },
      { label: 'Seasonal', value: 'Seasonal' },
    ]
  },
  salaryEmployer1: {
    answer: null,
    required: false,
    type: 'salaryEmployer1',
    label: 'Salary',
    inputPropType: 'text'
  },
  supervisorNameEmployer1: {
     answer: null,
     required: false,
     type: 'supervisorNameEmployer1',
     label: 'Supervisor Name',
     inputPropType: 'text'
  },
  natureOfWorkEmployer1: {
     answer: null,
     required: false,
     type: 'natureOfWorkEmployer1',
     label: 'Nature of work',
     inputPropType: 'text'
  },
  reasonForLeavingEmployer1: {
     answer: null,
     required: false,
     type: 'reasonForLeavingEmployer1',
     label: 'Reason for leaving',
     multiline: true,
     inputPropType: 'text'
  },


  // EMPLOYER 2
  nameEmployer2: {
    questionTextHeader: 'Employer 2',
     answer: null,
     required: false,
     type: 'nameEmployer2',
     label: 'Employer Name',
     inputPropType: 'text'
  },
  phoneEmployer2: {
    answer: null,
    required: false,
    type: 'phoneEmployer2',
    label: 'Phone Number (Employer 2)',
    inputPropType: 'tel'
  },
  addressStreetLine1Employer2: {
    questionText: 'Address (Employer 2)',
    answer: null,
    required: false,
    type: 'addressStreetLine1Employer2',
    label: 'Street Address (Employer 2)',
    inputPropType: 'text',
    subFields: [
      {key: 'addressStreetLine1Employer2', label: 'Street Address', keep: true},
      {key: 'addressStreetLine2Employer2'},
      {key: 'addressCityEmployer2'},
      {key: 'addressStateEmployer2'},
      {key: 'addressZipCodeEmployer2'},
    ]
  },
  addressStreetLine2Employer2: {
    // questionText: 'What is your last name?',
    answer: null,
    required: false,
    type: 'addressStreetLine2Employer2',
    label: 'Street Address Line 2',
    inputPropType: 'text'
  },
  addressCityEmployer2: {
    // questionText: 'What is your last name?',
    answer: null,
    required: false,
    type: 'addressCityEmployer2',
    label: 'City',
    inputPropType: 'text'
  },
  addressStateEmployer2: {
    answer: null,
    required: false,
    type: 'addressStateEmployer2',
    label: 'State/Province',
    inputPropType: 'text'
  },
  addressZipCodeEmployer2: {
    answer: null,
    required: false,
    type: 'addressZipCodeEmployer2',
    label: 'Postal / Zip Code',
    inputPropType: 'zip'
  },
  startDateEmployer2: {
    answer: null,
    required: false,
    type: 'startDateEmployer2',
    label: 'Start Date',
    inputPropType: 'date'
  },
  endDateEmployer2: {
    answer: null,
    required: false,
    type: 'endDateEmployer2',
    label: 'Start Date',
    inputPropType: 'date'
  },
  employmentTypeEmployer2: {
    questionText: 'Type of employment',
    answer: null,
    required: false,
    type: 'employmentTypeEmployer2',
    options: [
      { label: 'Full-Time', value: 'Full-Time' },
      { label: 'Part-Time', value: 'Part-Time' },
      { label: 'Seasonal', value: 'Seasonal' },
    ]
  },
  salaryEmployer2: {
    answer: null,
    required: false,
    type: 'salaryEmployer2',
    label: 'Salary',
    inputPropType: 'text'
  },
  supervisorNameEmployer2: {
     answer: null,
     required: false,
     type: 'supervisorNameEmployer2',
     label: 'Supervisor Name',
     inputPropType: 'text'
  },
  natureOfWorkEmployer2: {
     answer: null,
     required: false,
     type: 'natureOfWorkEmployer2',
     label: 'Nature of work',
     inputPropType: 'text'
  },
  reasonForLeavingEmployer2: {
     answer: null,
     required: false,
     type: 'reasonForLeavingEmployer2',
     label: 'Reason for leaving',
     multiline: true,
     inputPropType: 'text'
  },

  // EMPLOYER 3
  nameEmployer3: {
    questionTextHeader: 'Employer 3',
     answer: null,
     required: false,
     type: 'nameEmployer3',
     label: 'Employer Name',
     inputPropType: 'text'
  },
  phoneEmployer3: {
    answer: null,
    required: false,
    type: 'phoneEmployer3',
    label: 'Phone Number (Employer 2)',
    inputPropType: 'tel'
  },
  addressStreetLine1Employer3: {
    questionText: 'Address (Employer 2)',
    answer: null,
    required: false,
    type: 'addressStreetLine1Employer3',
    label: 'Street Address (Employer 2)',
    inputPropType: 'text',
    subFields: [
      {key: 'addressStreetLine1Employer3', label: 'Street Address', keep: true},
      {key: 'addressStreetLine2Employer3'},
      {key: 'addressCityEmployer3'},
      {key: 'addressStateEmployer3'},
      {key: 'addressZipCodeEmployer3'},
    ]
  },
  addressStreetLine2Employer3: {
    // questionText: 'What is your last name?',
    answer: null,
    required: false,
    type: 'addressStreetLine2Employer3',
    label: 'Street Address Line 2',
    inputPropType: 'text'
  },
  addressCityEmployer3: {
    // questionText: 'What is your last name?',
    answer: null,
    required: false,
    type: 'addressCityEmployer3',
    label: 'City',
    inputPropType: 'text'
  },
  addressStateEmployer3: {
    answer: null,
    required: false,
    type: 'addressStateEmployer3',
    label: 'State/Province',
    inputPropType: 'text'
  },
  addressZipCodeEmployer3: {
    answer: null,
    required: false,
    type: 'addressZipCodeEmployer3',
    label: 'Postal / Zip Code',
    inputPropType: 'zip'
  },
  startDateEmployer3: {
    answer: null,
    required: false,
    type: 'startDateEmployer3',
    label: 'Start Date',
    inputPropType: 'date'
  },
  endDateEmployer3: {
    answer: null,
    required: false,
    type: 'endDateEmployer3',
    label: 'Start Date',
    inputPropType: 'date'
  },
  employmentTypeEmployer3: {
    questionText: 'Type of employment',
    answer: null,
    required: false,
    type: 'employmentTypeEmployer3',
    options: [
      { label: 'Full-Time', value: 'Full-Time' },
      { label: 'Part-Time', value: 'Part-Time' },
      { label: 'Seasonal', value: 'Seasonal' },
    ]
  },
  salaryEmployer3: {
    answer: null,
    required: false,
    type: 'salaryEmployer3',
    label: 'Salary',
    inputPropType: 'text'
  },
  supervisorNameEmployer3: {
     answer: null,
     required: false,
     type: 'supervisorNameEmployer3',
     label: 'Supervisor Name',
     inputPropType: 'text'
  },
  natureOfWorkEmployer3: {
     answer: null,
     required: false,
     type: 'natureOfWorkEmployer3',
     label: 'Nature of work',
     inputPropType: 'text'
  },
  reasonForLeavingEmployer3: {
     answer: null,
     required: false,
     type: 'reasonForLeavingEmployer3',
     label: 'Reason for leaving',
     multiline: true,
     inputPropType: 'text'
  },

}

export const initialState = {
    form: {
        currentQuestion: null,
        questions,
        review: false,
        submittingForm: false,
        submitFormError: null,
        open: false,
        submitSuccess: false
    }
};


export default function projectStart (state = initialState, action) {
    switch (action.type) {
        case UPDATE: {
            const { keypath, value } = action;
            const input = pipe(state.form.questions,
              q => keys(q),
              ks => reject(k => state.form.questions?.[k]?.noInput, ks),
              ks => ks.reduce((acc, k) => pipe(acc,
                a => assocPath([k, 'value'], state.form.questions?.[k]?.answer?.value, a),
                a => assocPath(
                  [k, 'label'],
                  state.form.questions?.[k]?.label
                    || state.form.questions?.[k]?.questionText
                    || state.form.questions?.[k]?.questionTextHeader, a),
              ), {})
            );
            // console.log(JSON.stringify(input));
            return assocPath(keypath, value, state);
        }
        case SUBMIT_FORM: {
          const newState = pipe(state,
            st => assocPath(['form', 'submittingForm'], true, st),
          );
          const input = {
              // email: state.form.questions.email.answer?.value,
              // phone: state.form.questions.phone.answer?.value,
              // firstName: state.form.questions.firstName.answer?.value,
              // lastName: state.form.questions.lastName.answer?.value,
              // organizationName: state.form.questions.organizationName.answer?.value,
              // customerMessage: state.form.questions.customerMessage.answer?.value,
          };
          const html = state.form.report;
          const files = state.form.reportFiles;
          return loop(
            newState,
            Cmd.run(submitFormHelper, {
              args: [{input, html, files}],
              successActionCreator: onSubmitFormSuccess,
              failActionCreator: onSubmitFormFail
            })
          )
        }
        case SUBMIT_FORM_SUCCESS: {
          const newState = pipe(state,
            st => assocPath(['form', 'submittingForm'], false, st),
            st => assocPath(['form'], initialState.form, st),
            st => assocPath(['form', 'submitSuccess'], true, st),
          );
          return newState;
        }
        case SUBMIT_FORM_FAIL: {
          const { result } = action;
          const newState = pipe(state,
            st => assocPath(['form', 'submittingForm'], false, st),
            st => assocPath(['form', 'submitFormError'], result, st),
          );
          return newState;
        }
        default:
            return state;
    }
}

//++++++++++++++++++
// ACTIONS CREATORS+
//++++++++++++++++++


export function update(keypath, value) {
    return {
        type: UPDATE,
        keypath,
        value
    };
}

export function submitForm () {
  return {
    type: SUBMIT_FORM
  }
}

function onSubmitFormSuccess () {
  return {
    type: SUBMIT_FORM_SUCCESS
  }
}
function onSubmitFormFail (result) {
  return {
    type: SUBMIT_FORM_FAIL,
    result
  }
}

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_user_files_s3_bucket": "asphaltanimalsbucket172348-staging",
    "aws_user_files_s3_bucket_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:2a091a6f-8701-4192-950b-5e97f2e3616f",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_1OcJzW8k9",
    "aws_user_pools_web_client_id": "2ie3lpcapcsc0r62guqnrooubh",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://gfasbdwkvjacnl2k2rac6kwrta.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-vvzm4ynokfc25hvzafe76yxtce",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://89awl5ozze.execute-api.us-east-2.amazonaws.com/staging",
            "region": "us-east-2"
        }
    ]
};


export default awsmobile;

/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createProjectForm = /* GraphQL */ `
  mutation CreateProjectForm(
    $input: CreateProjectFormInput!
    $condition: ModelProjectFormConditionInput
  ) {
    createProjectForm(input: $input, condition: $condition) {
      id
      firstName
      lastName
      organizationName
      organizationAddress
      email
      phone
      jobAddress
      previousCustomer
      referralType
      serviceType
      customerMessage
      createdAt
      updatedAt
    }
  }
`;
export const updateProjectForm = /* GraphQL */ `
  mutation UpdateProjectForm(
    $input: UpdateProjectFormInput!
    $condition: ModelProjectFormConditionInput
  ) {
    updateProjectForm(input: $input, condition: $condition) {
      id
      firstName
      lastName
      organizationName
      organizationAddress
      email
      phone
      jobAddress
      previousCustomer
      referralType
      serviceType
      customerMessage
      createdAt
      updatedAt
    }
  }
`;
export const deleteProjectForm = /* GraphQL */ `
  mutation DeleteProjectForm(
    $input: DeleteProjectFormInput!
    $condition: ModelProjectFormConditionInput
  ) {
    deleteProjectForm(input: $input, condition: $condition) {
      id
      firstName
      lastName
      organizationName
      organizationAddress
      email
      phone
      jobAddress
      previousCustomer
      referralType
      serviceType
      customerMessage
      createdAt
      updatedAt
    }
  }
`;

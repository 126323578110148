// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-team-2-tsx": () => import("./../../../src/pages/team-2.tsx" /* webpackChunkName: "component---src-pages-team-2-tsx" */),
  "component---src-pages-thank-you-free-estimate-tsx": () => import("./../../../src/pages/thank-you-free-estimate.tsx" /* webpackChunkName: "component---src-pages-thank-you-free-estimate-tsx" */),
  "component---src-templates-about-page-tsx": () => import("./../../../src/templates/about-page.tsx" /* webpackChunkName: "component---src-templates-about-page-tsx" */),
  "component---src-templates-careers-tsx": () => import("./../../../src/templates/careers.tsx" /* webpackChunkName: "component---src-templates-careers-tsx" */),
  "component---src-templates-contact-us-tsx": () => import("./../../../src/templates/contact-us.tsx" /* webpackChunkName: "component---src-templates-contact-us-tsx" */),
  "component---src-templates-faq-page-tsx": () => import("./../../../src/templates/faq-page.tsx" /* webpackChunkName: "component---src-templates-faq-page-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-our-customers-page-tsx": () => import("./../../../src/templates/our-customers-page.tsx" /* webpackChunkName: "component---src-templates-our-customers-page-tsx" */),
  "component---src-templates-service-page-tsx": () => import("./../../../src/templates/service-page.tsx" /* webpackChunkName: "component---src-templates-service-page-tsx" */)
}


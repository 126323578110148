import { combineReducers } from 'redux-loop';
import project from './project/ducks';
import contact from './contact/ducks';
import careers from './careers/ducks';

const rootReducer = combineReducers({
    project,
    contact,
    careers
});
export default rootReducer;
